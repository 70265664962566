<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-5
          h1.text-center 支払情報
          .form-group.row.text-left
            div.col-5 プラン
            div.col-7 :  有料
          .form-group.row.text-left
            div.col-5 利用料
            div.col-7 :  月額300円(税込み)
          .form-group.row.text-left
            div.col-5 方法
            div.col-7 :  {{ payMethod }}
          .formp-group.row.text-left
            div.col-5 次回支払日
            div.col-7 :  {{ nextPayment.replace(/-/g, '/') }}
          router-link.btn.btn-primary.mt-3.col-md-12(to="/user/payment/upd_input" v-if="isCredit") カード情報変更
          br
          router-link.btn.btn-secondary.mt-3.col-md-12(to="/user/payment/del_input") 無料プランに変更
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      payMethod: '',
      nextPayment: '',
      isCredit: false,
    }
  },
  mounted() {
    const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user'
    axios.get(jsonUrl, {
      headers: {
        'Authorization': this.$user.idToken
      }
    })
    .then(res => {
      this.nextPayment = res.data.nextPaymentDate
      if(res.data.payment == "credit") {
        this.payMethod = 'カード'
        this.isCredit = true
      }else if(res.data.payment == "docomo") {
        this.payMethod = 'docomo'
      }else if(res.data.payment == "au") {
        this.payMethod = 'au'
      }else if(res.data.payment == "softbank2") {
        this.payMethod = 'SoftBank'
      }else {
        this.$router.push('/')
        return
      }
    })
    .catch(err => {
      console.log(err)
    })
  }
}
</script>
